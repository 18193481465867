<template>
  <div class="mx-auto my-12 flex w-full items-end justify-center">
    <RevToast
      :close-alternative-text="toastContent.closeAlternativeText"
      :icon-src="toastStyle.iconSrc"
      name="TOAST_COPY_CLIPBOARD"
      :opened="isToastOpen"
      :title="toastContent.title"
      to="#toast-wrapper"
      :variant="toastStyle.variant"
      @close="closeToast"
    >
      {{ toastContent.text }}
    </RevToast>

    <RevInputText
      id="copy-clipboard-input"
      class="mr-3 w-full"
      :label="inputLabel"
      :model-value="value"
    />
    <RevButton variant="primary" @click="handleClick">
      {{ buttonLabel }}
    </RevButton>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'

import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'
import { isEmpty } from '@backmarket/utils/object/isEmpty'
import { RevButton } from '@ds/components/Button'
import { RevInputText } from '@ds/components/InputText'
import { RevToast } from '@ds/components/Toast'

import { copyToClipboard } from './CopyClipBoard.helper'

const tracking = useTracking()
const isToastOpen = ref(false)

type ToastStyleVariant = InstanceType<typeof RevToast>['$props']['variant']

export type CopyClipBoardProps = {
  value: string
  valueToCopy?: string
  name: string
  zone: string
  inputLabel: string
  buttonLabel: string
  toastContent: {
    title: string
    text: string
    closeAlternativeText: string
  }
  toastStyle?: {
    variant: ToastStyleVariant
    iconSrc: string
  }
}

const props = withDefaults(defineProps<CopyClipBoardProps>(), {
  valueToCopy: '',
  toastStyle: () => ({
    variant: 'success',
    iconSrc: '/img/toast/rabbit.svg',
  }),
})

const closeToast = () => {
  isToastOpen.value = false
}

const openToast = () => {
  isToastOpen.value = true
}

const handleClick = () => {
  const value = !isEmpty(props.valueToCopy) ? props.valueToCopy : props.value

  if (copyToClipboard(value)) {
    openToast()

    tracking.trackClick({
      name: props.name,
      zone: props.zone,
      value: props.value,
    })
  }
}
</script>
