<template>
  <div>
    <div
      class="bg-static-info-mid rounded-lg mx-auto flex w-full items-center gap-16 rounded-b-none p-16 md:w-[41.25rem]"
    >
      <RevAvatar
        class="mood-blush"
        data-test="partner-avatar"
        :label="''"
        size="medium"
        thumbnail="/img/buyback/ecosystem-logo.svg"
      />
      <p class="body-1-bold" data-test="partner-name-unformatted">
        {{ i18n(translations.partnerName) }}
      </p>
    </div>
    <div
      class="bg-float-default-low rounded-lg body-2 rounded-t-none px-24 pb-24 pt-16"
    >
      <FormattedMessage
        data-test="partner-content"
        :definition="translations.partnerContent"
      >
        <template #collector>
          <span class="body-2-bold" data-test="partner-name-formatted">
            {{ i18n(translations.partnerName) }}
          </span>
        </template>
        <template #recycler>
          <span class="body-2-bold"
            >{{ i18n(translations.recyclerName) }}
          </span>
        </template>
      </FormattedMessage>
    </div>
  </div>
</template>

<script setup lang="ts">
import FormattedMessage from '@backmarket/nuxt-module-i18n/FormattedMessage.vue'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevAvatar } from '@ds/components/Avatar'

import translations from './RecyclingPartnerBlock.translations'

const i18n = useI18n()
</script>
