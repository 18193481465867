export default {
  partnerContent: {
    id: 'buyback_no_offer_recycling_description',
    defaultMessage:
      '{collector, html} organizes the collection of devices which are then sent directly to {recycler, html}, specialists in sorting devices and giving them second lives. ',
  },
  partnerName: {
    id: 'buyback_no_offer_recycling_partner',
    defaultMessage: 'Ecosystem',
  },
  recyclerName: {
    id: 'buyback_no_offer_recycling_partner_helper',
    defaultMessage: 'Emmaüs France',
  },
}
