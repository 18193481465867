<template>
  <div>
    <RevTag v-if="badge" :label="badge" variant="info" />
    <h1 class="heading-1 pr-5" :class="textColorClass">
      {{ title }}
    </h1>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import { BannerTextColor } from '@backmarket/http-api/src/api-specs-content/models/banner'
import { RevTag } from '@ds/components/Tag'

import { getBannerTextColorClass } from '../../helpers/banner/getTextColor'

const props = withDefaults(
  defineProps<{
    title?: string
    badge?: string
    textColor?: BannerTextColor
  }>(),
  {
    title: '',
    textColor: BannerTextColor.Black,
    badge: '',
  },
)

const textColorClass = computed(() => {
  return getBannerTextColorClass(props.textColor)
})
</script>
