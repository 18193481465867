export const copyToClipboard = (value: string) => {
  // We need to create a new element
  // to set the value in the clipboard
  // as the input is disabled
  const fakeElement = document.createElement('textarea')
  fakeElement.value = value
  document.body.appendChild(fakeElement)

  fakeElement.select()
  fakeElement.setSelectionRange(0, 99999)

  const isCopied = document.execCommand('copy')

  document.body.removeChild(fakeElement)

  return isCopied
}
