export default {
  greetings: {
    id: 'get_visitor_id_greetings',
    defaultMessage: 'Can we help you?',
  },

  paragraph: {
    id: 'get_visitor_id_paragraph',
    defaultMessage:
      'This identifier will help our technical teams to provide you some assistance.',
  },

  button: {
    id: 'get_visitor_id_clipboard_button',
    defaultMessage: 'Copy',
  },

  label: {
    id: 'get_visitor_id_clipboard_label',
    defaultMessage: 'your identifier',
  },

  toastCopiedTitle: {
    id: 'get_visitor_id_toast_copied_title',
    defaultMessage: 'Success!',
  },

  toastCopiedContent: {
    id: 'get_visitor_id_toast_copied_content',
    defaultMessage: 'The visitor ID has been copied.',
  },

  toastCopiedClose: {
    id: 'get_visitor_id_toast_copied_close',
    defaultMessage: 'Close',
  },
}
