import { type Ref, computed } from 'vue'

import { type IconColor } from '@backmarket/http-api/src/api-specs-content/models/icon'
import { tw } from '@backmarket/utils/string/tw'

/**
 * TODO: check with DS why fill-<color> classes are completely different with new DS
 */

const COLOR_CLASS_MAP: Record<IconColor, string> = {
  primary: tw`text-static-default-hi`,
  secondary: tw`mood-inverse text-static-default-hi`,
  success: tw`text-static-success-hi`,
  info: tw`text-static-info-hi`,
  danger: tw`text-static-danger-hi`,
  warning: tw`text-static-warning-hi`,
}

export function useColorClass(color: Ref<IconColor>) {
  return computed(() => COLOR_CLASS_MAP[color.value])
}
