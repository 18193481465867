export default {
  breadcrumb: {
    id: 'breadcrumb_aria_label',
    defaultMessage: 'Breadcrumb',
  },
  breadcrumbHome: {
    id: 'buyback_breadcrumb_home',
    defaultMessage: 'Home',
  },

  breadcrumbCategories: {
    id: 'buyback_breadcrumb_categories',
    defaultMessage: 'Sell your device',
  },

  breadcrumbDevice: {
    id: 'buyback_breadcrumb_device',
    defaultMessage: 'Device',
  },

  breadcrumbOffer: {
    id: 'buyback_breadcrumb_offer',
    defaultMessage: 'Offer',
  },

  breadcrumbBank: {
    id: 'buyback_breadcrumb_bank',
    defaultMessage: 'Bank',
  },

  breadcrumbAddress: {
    id: 'buyback_breadcrumb_address',
    defaultMessage: 'Address',
  },

  breadcrumbShipping: {
    id: 'buyback_breadcrumb_shipping',
    defaultMessage: 'Shipping',
  },
}
