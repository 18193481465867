<template>
  <RevBreadcrumb
    :ariaLabel="i18n(translations.breadcrumb)"
    :breads="breads"
    class="mb-24"
    @click-breadcrumb="handleClick"
  />
</template>

<script setup lang="ts">
import { useRoute } from '#imports'
import { computed } from 'vue'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevBreadcrumb } from '@ds/components/Breadcrumb'

import { useRouteLocationWithLocale } from '../../../../composables/useRouteLocationWithLocale'
import { HOME } from '../../../home/route-names'

import translations from './Breadcrumb.translations'
import type { Crumb } from './crumb'

const resolveLocalizedRoute = useRouteLocationWithLocale()

const route = useRoute()

const i18n = useI18n()

const props = defineProps<{
  currentPageTitle: string
  intermediaryPath: Crumb[]
}>()

const emit = defineEmits<{
  (event: 'breadcrumb-clicked', breadcrumbTitle: string): void
}>()

const breads = computed(() => [
  {
    link: resolveLocalizedRoute({ name: HOME }),
    title: i18n(translations.home),
  },
  ...props.intermediaryPath,
  {
    link: route.path,
    title: props.currentPageTitle,
  },
])

function handleClick(breadcrumbTitle: string) {
  emit('breadcrumb-clicked', breadcrumbTitle)
}
</script>
