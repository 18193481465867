<template>
  <RevContainer>
    <p class="body-1-bold mt-36">
      {{ i18n(translations.greetings) }}
    </p>

    <p class="body-2 mt-12">
      {{ i18n(translations.paragraph) }}
    </p>

    <CopyClipBoard
      :button-label="i18n(translations.button)"
      class="mt-52 items-stretch"
      :input-label="i18n(translations.label)"
      name="debug_modal_clipboard"
      :toast-content="toastContent"
      :value="visitorId"
      zone="footer"
    />
  </RevContainer>
</template>

<script lang="ts" setup>
import { useHead } from '#imports'

import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useVisitorId } from '@backmarket/nuxt-module-identification/useVisitorId'
import { RevContainer } from '@ds/components/Container'

import CopyClipBoard from '../components/CopyClipBoard/CopyClipBoard.vue'

import translations from './VisitorId.translations'

const i18n = useI18n()
const visitorId = useVisitorId()

const toastContent = {
  title: i18n(translations.toastCopiedTitle),
  text: i18n(translations.toastCopiedContent),
  closeAlternativeText: i18n(translations.toastCopiedClose),
}

useHead({
  meta: [
    {
      vmid: 'robots',
      name: 'robots',
      content: 'noindex',
    },
  ],
})
</script>
