export default {
  title: {
    id: 'buyback_funnel_no_offer_title_v2',
    defaultMessage: "Sorry, we didn't find an offer.",
  },
  paragraph: {
    id: 'buyback_funnel_no_offer_paragraph_v2',
    defaultMessage:
      "We know it's a bummer to not get an offer — this can happen when your device is too old or too damaged.",
  },
  recyclingParagraph: {
    id: 'buyback_funnel_no_offer_paragraph_recycling',
    defaultMessage:
      "But don't worry, you can still do a good deed by recycling it!",
  },
  infoBlockTitle: {
    id: 'buyback_no_offer_info_block_recycling_title',
    defaultMessage:
      "But don't worry, you can still give your device a second life!",
  },
  infoBlockParagraph: {
    id: 'buyback_no_offer_recycling_subtitle',
    defaultMessage:
      "Old devices contain lots of valuable raw materials and we make sure as many parts as possible are reused by qualified recyclers when you recycle with us. It's free and easy.",
  },
  cta: {
    id: 'buyback_no_offer_recycling_cta',
    defaultMessage: 'Recycle your device',
  },
  partnerTitle: {
    id: 'buyback_no_offer_recycling_about',
    defaultMessage: 'About our recycling partnership',
  },
  button: {
    id: 'buyback_no_offer_button',
    defaultMessage: 'Buyback',
  },
}
